import React from "react";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Home from "./pages/home";
import About from "./pages/about";
import MyProjects from "./pages/myprojects";
import MyResume from "./pages/myresume";
import WorkEnquiry from "./pages/workenquiry";
import ContactDetails from "./pages/contactdetails";
import Footer from "./Footer";

import "./App.css";

function App() {
  return (
    <Router>
      <Navbar />
      <Container className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/myprojects" element={<MyProjects />} />
          <Route path="/myresume" element={<MyResume />} />
          <Route path="/workenquiry" element={<WorkEnquiry />} />
          <Route path="/contactdetails" element={<ContactDetails />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
