import React from "react";
import { Link } from "react-router-dom";

import "../pages.css";

const Home = () => {
  return (
    <div className="Home">
      <h2>Welcome to</h2>
      <img
        src="SonjaCodes-Logowborder.png"
        alt="SonjaCodes logo"
        className="img img-fluid"
      />
      <h3 className="mb-5">It is a pleasure to e-meet you!</h3>
      <p className="text-center">
        My name is <span className="SonjaCodes">Sonja</span> and I am a{" "}
        <strong>Frontend Developer</strong> based in Zurich, Switzerland🇨🇭
        <br />
        <br />
        The <span className="SonjaCodes">SonjaCodes</span> brand was created
        during my studies at SheCodes.io. Due to a lifechanging event, I
        discovered my passion and unique aptitude for coding. I am certain that
        it was a sign from destiny.
        <br />
        From the design, to the functionality, to making it responsive, to using
        newer/better technologies, to simply everything to do with it, I love
        it! My course of action being: there is no such thing as lacking the
        knowledge/skill, I have simply not learned it <strong>yet</strong>.
        Constantly on the lookout to ameliorate, learn, transform, and create.
        <br />
        <br />
        And hey, they do say after all...
        <br />
        <i>
          "If you do what you love, you will never work a day in your life."
        </i>
      </p>
      <div className="navigation-links">
        <Link to="/about" className="btn btn-primary mb-3" title="About">
          Find out more
        </Link>
      </div>
    </div>
  );
};

export default Home;
