import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: #d9d9d9;
  height: 66px;
  display: flex;
  justify-content: space-between;
  padding: 2px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

export const NavToggler = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 66px;
    height: 66px;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: relative;
    right: 2px;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 64px;
    height: 4px;
    background: #333;
    border-radius: 2px;
    position: absolute;
    transition: all 0.3s ease;
  }

  &::before {
    top: 15px;
  }

  &::after {
    top: 45px;
  }

  &:hover::before,
  &:hover::after {
    background: var(--sonjacodes-brand-color);
  }
`;

export const NavMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    position: absolute;
    top: 66px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: #d9d9d9;
    padding: 20px;
  }
`;

export const NavMenuItems = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #f53b9b;
    font-weight: bold;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
`;
