import React from "react";
import Projects from "./projects";

export default function ProjectsList({ projects }) {
  return (
    <div className="card-grid">
      {projects.map((project) => {
        return <Projects key={project.id} project={project} />;
      })}
    </div>
  );
}
