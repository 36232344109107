import React from "react";
import Slider from "react-infinite-logo-slider";

const SliderComponent = () => {
  return (
    <Slider
      width="88px"
      duration={20}
      pauseOnHover={true}
      blurBorders={true}
      blurBorderColor={"#fff"}>
      <Slider.Slide>
        <img
          src="../icons/htmlcss.png"
          alt="HTML CSS Icon"
          title="HTML CSS"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/javascript.png"
          alt="JavaScript Icon"
          title="JavaScript"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/bootstrap.png"
          alt="Bootstrap Icon"
          title="Bootstrap"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/react.png"
          alt="React Icon"
          title="React"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/visual-studio.png"
          alt="VSCode Icon"
          title="VSCode"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/svg.png"
          alt="SVG Icon"
          title="SVG"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/replit.png"
          alt="Replit Icon"
          title="Replit"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/python.png"
          alt="Python Icon"
          title="Python"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/npm.png"
          alt="NPM Icon"
          title="NPM"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/github.png"
          alt="GitHub Icon"
          title="GitHub"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/figma.png"
          alt="Figma Icon"
          title="Figma"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/terminal.png"
          alt="Terminal Icon"
          title="Terminal"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/api.png"
          alt="API Icon"
          title="API"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/responsive-design.png"
          alt="Responsive Design Icon"
          title="Responsive Design"
          className="skillIcon"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img
          src="../icons/seo.png"
          alt="SEO Icon"
          title="SEO"
          className="skillIcon"
        />
      </Slider.Slide>
    </Slider>
  );
};

export default SliderComponent;
