import React, { useState } from "react";
import {
  Nav,
  NavLink,
  NavMenu,
  NavMenuItems,
  NavToggler,
} from "./NavbarElements";
import { useLocation } from "react-router-dom";

import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  const handleContactClick = () => {
    setIsContactOpen(false);
  };

  const handleSublinkClick = () => {
    toggleMenu();
  };

  return (
    <>
      <Nav className="navbar">
        <a className="navbar-brand" href="/" title="Homepage">
          <img
            src="SonjaCodes-Logowborder.png"
            alt="SonjaCodes logo"
            className="logo d-inline-block align-left"
            title="Homepage"
            height="66px"
            padding="0"
            margin="0"
          />
        </a>

        <NavToggler onClick={toggleMenu} />

        <NavMenu isOpen={isOpen}>
          <NavMenuItems>
            <NavLink to="/" activeStyle onClick={handleMenuItemClick}>
              Home
            </NavLink>
            <NavLink to="/about" activeStyle onClick={handleMenuItemClick}>
              About me/My Skills
            </NavLink>
            <NavLink to="/myprojects" activeStyle onClick={handleMenuItemClick}>
              My Projects
            </NavLink>
            <NavLink to="/myresume" activeStyle onClick={handleMenuItemClick}>
              My Resume
            </NavLink>
            <NavLink
              to="/contact"
              activeStyle
              style={{
                color:
                  location.pathname === "/workenquiry" ||
                  location.pathname === "/contactdetails"
                    ? "#f53b9b"
                    : "#808080",
                fontWeight:
                  location.pathname === "/workenquiry" ||
                  location.pathname === "/contactdetails"
                    ? "bold"
                    : "normal",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleContactClick();
                setIsContactOpen(!isContactOpen);
              }}>
              Contact
              {isContactOpen && (
                <div className="contact-options">
                  <NavLink
                    to="/workenquiry"
                    className="contact-option"
                    onClick={handleSublinkClick}>
                    Work Enquiry
                  </NavLink>
                  <NavLink
                    to="/contactdetails"
                    className="contact-option"
                    onClick={handleSublinkClick}>
                    Contact Details
                  </NavLink>
                </div>
              )}
            </NavLink>
          </NavMenuItems>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
