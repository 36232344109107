import React, { useState } from "react";

export default function Projects({ project }) {
  const [flip, setFlip] = useState(false);

  return (
    <div
      className={`card ${flip ? "flip" : ""}`}
      onClick={() => setFlip(!flip)}>
      <div className="front">{project.img}</div>
      <div className="back">
        <h3>{project.name}</h3>
        <h4>{project.website}</h4>
        {project.description}
      </div>
    </div>
  );
}
