import React, { useState, useRef } from "react";
import { Container } from "react-bootstrap";

import emailjs from "@emailjs/browser";

import "../pages.css";

export const WorkEnquiry = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    setButtonClicked(true);

    emailjs
      .sendForm("service_50ssum9", "template_gvmlmng", form.current, {
        publicKey: "V8RkhY-AqFYtN5GSC",
      })
      .then(
        () => {
          setEmailSent(true);
          setButtonClicked(false);
        },
        (error) => {
          alert("Error sending email:", error.text);
          setButtonClicked(false);
        }
      );
  };

  return (
    <Container className="WorkEnquiry">
      <h1>Work Enquiry</h1>
      <p>
        Please complete the form below and I will get back to you as soon as
        possible.
      </p>
      <div className="form-container">
        {emailSent ? (
          <h3>Success! Your message has been sent.</h3>
        ) : (
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="user_name" placeholder="Your name" />
            <input type="email" name="user_email" placeholder="Your email" />
            <select name="category" required>
              <option value="">Select...</option>
              <option value="Content Development">Content Development</option>
              <option value="Web Development">Web Development</option>
              <option value="Responsive Design Support">
                Responsive Design Support
              </option>
              <option value="Support with existing website">
                Support with existing website
              </option>
              <option value="Questions about my website/my work">
                Questions about my website/my work
              </option>
              <option value="Other">Other (provide details below)</option>
            </select>
            <label forTextArea>Message</label>
            <textarea name="message" />
            <input
              type="submit"
              value={buttonClicked ? "Sending..." : "Submit"}
              disabled={buttonClicked}
            />
          </form>
        )}
      </div>
    </Container>
  );
};

export default WorkEnquiry;
