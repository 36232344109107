import { FaGithub, FaLinkedinIn, FaAt } from "react-icons/fa6";

import "./Footer.css";

export default function Footer() {
  return (
    <footer className="Footer">
      <div className="Links">
        <a
          href="mailto:sonja@sonjacodes.com"
          title="Email"
          style={{ color: "var(--sonjacodes-brand-color)" }}>
          <FaAt size="30px" />
        </a>
        <a
          href="https://www.linkedin.com/in/sonjakolmer/"
          target="_blank"
          rel="noreferrer"
          title="LinkedIn"
          style={{ color: "var(--sonjacodes-brand-color)" }}>
          <FaLinkedinIn size="30px" />
        </a>
        <a
          href="https://github.com/SonjaCodes/"
          target="_blank"
          rel="noreferrer"
          title="Github"
          style={{ color: "var(--sonjacodes-brand-color)" }}>
          <FaGithub size="30px" />
        </a>
      </div>
      <div className="copyright">
        This website was coded with love by{" "}
        <a
          href="https://www.shecodes.io/graduates/123085-sonja-kolmer"
          target="_blank"
          rel="noreferrer">
          SonjaCodes
        </a>{" "}
        and is hosted via{" "}
        <a
          href="https://app.netlify.com/sites/sonjacodes/overview"
          target="_blank"
          rel="noreferrer">
          Netlify
        </a>
        .
        <br />
        <br />© all rights reserved
      </div>
    </footer>
  );
}
