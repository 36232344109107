import React from "react";
import { FaMapPin, FaPhoneVolume, FaAt } from "react-icons/fa6";

import "../pages.css";

export default function ContactDetails() {
  return (
    <div className="ContactDetails">
      <h2>Contact Details</h2>
      <div className="icon-text-columns">
        <div className="icon-column">
          <FaMapPin
            size="20px"
            style={{
              color: "var(--sonjacodes-brand-color)",
            }}
          />
          <FaPhoneVolume
            size="20px"
            style={{
              color: "var(--sonjacodes-brand-color)",
            }}
          />
          <FaAt
            size="20px"
            style={{
              color: "var(--sonjacodes-brand-color)",
            }}
          />
        </div>
        <div className="text-column">
          <span>Zurich, Switzerland</span>
          <span>+41 78 637 80 06</span>
          <span>info@sonjacodes.com</span>
        </div>
      </div>
      <a href="mailto:info@sonjacodes.com" className="btn btn-primary">
        Email me!
      </a>
    </div>
  );
}
