import React, { useState } from "react";
import { FaLink } from "react-icons/fa6";
import ProjectsList from "./projectsfolder/projectslist";

import "../pages.css";

export default function MyProjects() {
  const Projects = [
    {
      id: 1,
      name: "React Weather App",
      website: (
        <a
          href="https://sonjacodes-react-final-project.netlify.app/"
          target="_blank"
          rel="noreferrer"
          className="websiteLink">
          <FaLink
            size="30px"
            style={{ color: "var(--sonjacodes-brand-color-secondary)" }}
          />
        </a>
      ),
      description: (
        <a
          href="https://github.com/SonjaCodes/sonjacodes-react-final-project"
          target="_blank"
          rel="noreferrer"
          className="githubLink">
          See code on Github
        </a>
      ),
      img: (
        <img
          src="../project-images/react-weather-app.png"
          alt="React Weather App"
        />
      ),
    },
    {
      id: 2,
      name: "React Dictionary App",
      website: (
        <a
          href="https://sonjacodes-react-dictionary-app.netlify.app/"
          target="_blank"
          rel="noreferrer"
          className="websiteLink">
          <FaLink
            size="30px"
            style={{ color: "var(--sonjacodes-brand-color-secondary)" }}
          />
        </a>
      ),
      description: (
        <a
          href="https://github.com/SonjaCodes/sonjacodes-react-dictionary-app"
          target="_blank"
          rel="noreferrer"
          className="githubLink">
          See code on Github
        </a>
      ),
      img: (
        <img
          src="../project-images/react-dictionary-app.png"
          alt="React Dictionary App"
        />
      ),
    },
    {
      id: 3,
      name: "World Clock",
      website: (
        <a
          href="https://sonjacodes-world-clock-project.netlify.app/"
          target="_blank"
          rel="noreferrer"
          className="websiteLink">
          <FaLink
            size="30px"
            style={{ color: "var(--sonjacodes-brand-color-secondary)" }}
          />
        </a>
      ),
      description: (
        <a
          href="https://github.com/SonjaCodes/world-clock-project"
          target="_blank"
          rel="noreferrer"
          className="githubLink">
          See code on Github
        </a>
      ),
      img: <img src="../project-images/world-clock.png" alt="World Clock" />,
    },
    {
      id: 4,
      name: "Responsive Project",
      website: (
        <a
          href="https://sonjacodes-responsive-project.netlify.app/"
          target="_blank"
          rel="noreferrer"
          className="websiteLink">
          <FaLink
            size="30px"
            style={{ color: "var(--sonjacodes-brand-color-secondary)" }}
          />
        </a>
      ),
      description: (
        <a
          href="https://github.com/SonjaCodes/SonjaCodes-Responsive-Project"
          target="_blank"
          rel="noreferrer"
          className="githubLink">
          See code on Github
        </a>
      ),
      img: (
        <img
          src="../project-images/responsive-project.png"
          alt="Responsive Project"
        />
      ),
    },
    {
      id: 5,
      name: "Responsive Tribute Project",
      website: (
        <a
          href="https://sonjacodes-responsive-ad-on-project.netlify.app/"
          target="_blank"
          rel="noreferrer"
          className="websiteLink">
          <FaLink
            size="30px"
            style={{ color: "var(--sonjacodes-brand-color-secondary)" }}
          />
        </a>
      ),
      description: (
        <a
          href="https://github.com/SonjaCodes/SonjaCodes-Responsive-Ad-on-Project"
          target="_blank"
          rel="noreferrer"
          className="githubLink">
          See code on Github
        </a>
      ),
      img: (
        <img
          src="../project-images/responsive-tribute-project.png"
          alt="Responsive Tribute Project"
        />
      ),
    },
    {
      id: 6,
      name: "AI Quote Generator",
      website: (
        <a
          href="https://sonjacodes-quote-generator.netlify.app/"
          target="_blank"
          rel="noreferrer"
          className="websiteLink">
          <FaLink
            size="30px"
            style={{ color: "var(--sonjacodes-brand-color-secondary)" }}
          />
        </a>
      ),
      description: (
        <a
          href="https://github.com/SonjaCodes/inspirational-quote-generator"
          target="_blank"
          rel="noreferrer"
          className="githubLink">
          See code on Github
        </a>
      ),
      img: (
        <img
          src="../project-images/ai-quote-generator.png"
          alt="AI Quote Generator"
        />
      ),
    },
  ];

  const [projects] = useState(Projects);

  return (
    <div className="MyProjects">
      <h1>My Projects</h1>
      <p>These are some of my projects - Click for details</p>
      <ProjectsList projects={projects} />
    </div>
  );
}
